import { PublicClientApplication } from "@azure/msal-browser";

export const msalConfig = {
  auth: {
    clientId: "edaf0812-f343-4461-8510-55010c8488c4", // El ID de la aplicación en Azure
    authority: "https://login.microsoftonline.com/f52c2167-6ac6-4a6c-b8a8-8c05872ae346",
    //redirectUri: "http://localhost:3000/dashboard/", // Ajusta según tu entorno
    redirectUri: "https://quicornac.net/dashboard/"
  },
  cache: {
    cacheLocation: "sessionStorage", // Puedes usar "sessionStorage" también
    storeAuthStateInCookie: false,
  },
};

export const msalInstance = new PublicClientApplication(msalConfig);
