import ReactDOM from 'react-dom/client';
import { Suspense } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import { App } from './app';
import { msalInstance } from './msalConfig';
import { MsalProvider } from '@azure/msal-react';

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <HelmetProvider>
    <BrowserRouter>
      <MsalProvider instance={msalInstance}>
        <Suspense>
          <App />
        </Suspense>
      </MsalProvider>
    </BrowserRouter>
  </HelmetProvider>
);
